:root {
  // Theme Colors
  --primary: #004643;
  --secondary: #6b0f1a;
  --tertiary: #f4fffd;

  // Neutral Colors
  --neutral-100: #fff;
  --neutral-200: #f5f6fb;
  --neutral-300: #e7eaf4;
  --neutral-400: #c4c9d9;
  --neutral-500: #afb4c5;
  --neutral-600: #767b87;
  --neutral-700: #52555e;
  --neutral-800: #2b2c30;
  --neutral-900: #000;

  // Semantic Colors
  --success: #007e33;
  --warn: #ff8800;
  --error: #cc0000;
  --info: #0099cc;
}
